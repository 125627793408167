import React, { Component } from "react";
import { render } from "react-dom";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./time-slider-components"; // example render components - source below
import { addDays, startOfToday, format } from "date-fns";
import { scaleTime } from "d3-scale";
import { timeControlBox, timeSliderControl } from './style';


const sliderStyle = {
  position: "relative",
  width: "100%"
};

function formatTick(ms) {
  return format(new Date(ms), "MMM dd");
}

const oneDay = 1000 * 60 * 60 * 24;// ms/s * s/min * min/hour * hour/day

export class TimeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.initialDate,
      updated: props.initialDate,
      min: props.dateRangeMin,
      max: props.dateRangeMax
    };
  }

  componentDidMount() {
    console.log('TimeSlider.componentDidMount() invoked');
    this.onDatasetChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRangeMin !== this.props.dateRangeMin ||
      prevProps.dateRangeMax !== this.props.dateRangeMax
    ) {
      this.onDatasetChange();
    }
  }
  onDatasetChange() {

    console.log("TimeSlider.onDatasetChange invoked");

    this.setState({
      selected: this.props.initialDate,
      updated: this.props.initialDate,
      min: this.props.dateRangeMin,
      max: this.props.dateRangeMax
    });
  }

  onChange = ([ms]) => {
    this.setState({
      selected: new Date(ms)
    });
    this.props.onTimeSelected(this.state.selected);
  };

  onUpdate = ([ms]) => {
    this.setState({
      updated: new Date(ms)
    });
    this.props.onTimeUpdated(this.state.updated);
  };

  renderDateTime(date, header) {
    return (
      <div
        style={{
          display: "inline-block",
          width: "25%",
          textAlign: "left",
          fontFamily: "Arial",
          margin: 5
        }}
      >
        <b>{header}:</b>
        <div style={{ fontSize: 12, paddingLeft: 8, display: 'inline-block' }}>{format(date, "dd MMM yyyy")}</div>
      </div>
    );
  }

  render() {
    const { min, max, selected, updated } = this.state;

    const dateTicks = scaleTime()
      .domain([min, max])
      .ticks(8)
      .map(d => +d);

    return (
      <div style={timeControlBox}>
        {this.renderDateTime(selected, "Selected")}
        {this.renderDateTime(updated, "Updated")}
        <div style={timeSliderControl}>
          <Slider
            mode={1}
            step={oneDay}
            domain={[+min, +max]}
            rootStyle={sliderStyle}
            onUpdate={this.onUpdate}
            onChange={this.onChange}
            values={[+selected]}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div>
                  {handles.map(handle => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={[+min, +max]}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks right={false}>
              {({ tracks, getTrackProps }) => (
                <div>
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
            <Ticks values={dateTicks}>
              {({ ticks }) => (
                <div>
                  {ticks.map(tick => (
                    <Tick
                      key={tick.id}
                      tick={tick}
                      count={ticks.length}
                      format={formatTick}
                    />
                  ))}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>
      </div>
    );
  }
}

