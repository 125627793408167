export const tooltipStyle = {
  position: 'absolute',
  padding: '4px',
  background: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  maxWidth: '300px',
  fontSize: '10px',
  zIndex: 9,
  pointerEvents: 'none'
};

export const mapStylePicker = {
  background: '#fff',
  position: 'absolute',
  top: '20px',
  left: '20px',
  zIndex: 100
};

export const mapPicker = {
  position: 'absolute',
  background: '#29323C',
  border: '#3A4552',
  top: 17,
  right: 48,
  height: 30,
  width: 165,
  zIndex: 100,
  color: 'white',
  fontFamily: 'Arial',
  fontSize: 12,
  paddingLeft: 8
};

export const regionPicker = {
  position: 'absolute',
  background: '#29323C',
  border: '#3A4552',
  top: 17,
  right: 48,
  height: 30,
  width: 165,
  zIndex: 100,
  color: 'white',
  fontFamily: 'Arial',
  fontSize: 12,
  paddingLeft: 8
};

export const perRegionStatisticsChart = {
  borderRadius: 3,
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  fontFamily:
    'ff-clan-web-pro, "Helvetica Neue", Helvetica, sans-serif !important',
  fontSize: '12px',
  lineHeight: 1.833,
  width: "30%",
  height: '25%',
  position: 'absolute',
  bottom: '30px',
  left: '335px',
  padding: '10px',
  zIndex: 100,
  background: 'white',
  backgroundColor: '#242730',
  color: 'black'
};



export const layerControl = {
  borderRadius: 3,
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  fontFamily:
    'ff-clan-web-pro, "Helvetica Neue", Helvetica, sans-serif !important',
  fontSize: '12px',
  lineHeight: 1.833,
  width: 200,
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  padding: '20px',
  zIndex: 100,
  background: 'white'
};

export const charts = {
  background: 'white',
  borderRadius: 3,
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  fontFamily:
    'ff-clan-web-pro, "Helvetica Neue", Helvetica, sans-serif !important',
  fontSize: '12px',
  lineHeight: 1.833,
  height: 210,
  padding: '10px',
  position: 'absolute',
  left: 20,
  bottom: 20,
  width: 500,
  zIndex: 100
};

export const spinner = {
  animation: 'linear 2s infinite',
  background: '#1EACC7',
  borderRadius: '50%',
  height: 10,
  width: 10,
  opacity: 0.4,
  position: 'absolute',
  top: 70,
  left: 60,
  transformOrigin: '20px 20px',
  zIndex: 110
};


export const timeControlBox = {
  borderRadius: 3,
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  fontFamily:
    'ff-clan-web-pro, "Helvetica Neue", Helvetica, sans-serif !important',
  fontSize: '12px',
  lineHeight: 1.833,
  width: "40%",
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  padding: '10px',
  zIndex: 100,
  background: 'white',
  backgroundColor: '#242730',
  color: 'white'
};

export const timeSliderControl = {
  
  margin: "10px 0 -10px 5px",
  height: 50,
  width: "98%"
};
