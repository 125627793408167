/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:0faa2faf-c97f-4ab6-9e41-7d8f47ccb8f8",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_f3zovWHxN",
    "aws_user_pools_web_client_id": "3f3mng27g9honcvn3gn0gmk05p",
    "oauth": {}
};


export default awsmobile;
