import _ from "lodash";
import React, { Component } from "react";
import { Search, Grid, Header, Segment } from "semantic-ui-react";
import './Search.css';

const initialState = { isLoading: false, results: [], value: "" };
const maxDisplayedResults = 7;

export class SearchBox extends Component {
    /*
        this.props={
            onRegionSelect: function(result)
        }
    */

    state = initialState;

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.title });
        this.props.onRegionSelect(result);
    };

    handleSearchChange = (e, { value }) => {
        this.setState({ isLoading: true, value });

        setTimeout(() => {
            if (this.state.value.length < 1) return this.setState(initialState);

            const re = new RegExp(_.escapeRegExp(this.state.value), "i");
            const isMatch = result => re.test(result.title);

            this.setState({
                isLoading: false,
                results: _.take(_.filter(this.props.options, isMatch), maxDisplayedResults)
            });
        }, 300);
    };

    render() {
        const { isLoading, value, results } = this.state;

        return (
            <Search
                className= "search-field"
                placeholder="Search"
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                    leading: true
                })}
                results={results}
                value={value}
            // {...this.props}
            />
        );
    }
}
