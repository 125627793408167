import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar'

import { perRegionStatisticsChart } from '../../style';

import { Container, Header, List } from "semantic-ui-react";

import { SearchBox } from './searchbox';

import { dataBucketUrl } from '../../globalprops.js';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


export class PerRegionStatisticsChart extends Component {
    /*
        this.props={
            currentMap: { 
                label: 'Global', 
                path: 'Global/', 
                dataId: 'dailyData' 
            }
        }
    */
    state = {
        currentRegionsArray: [],
        barChartData: []
    };

    constructor(props) {
        super(props);

        // //populate the regions map
        // this.regions = countiesArray.reduce((map, obj) => (map[obj.fips] = obj, map), {});
        // console.log(this.regions);
    }

    componentDidMount() {
        console.log('PerRegionStatisticsChart.componentDidMount() invoked');
        const regionsUrl = dataBucketUrl + this.props.currentMap.path + "regions.json";

        console.log("fetching regions from: " + regionsUrl);
        this.onCurrentMapChange();
    }

    onCurrentMapChange() {

        // update the region options on the searchbox
        const regionsUrl = dataBucketUrl + this.props.currentMap.path + "regions.json";
        console.log("fetching regions from: " + regionsUrl);
        fetch(regionsUrl)
            .then(response => response.json())
            .then(regionsArray => {
                this.setState({ currentRegionsArray: regionsArray });
            });

        this.clearBarchart();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentMap !== this.props.currentMap) {
            this.onCurrentMapChange();
        }
    }

    clearBarchart() {
        this.setState({ barChartData: [] });
    }

    onRegionPickerSelect = (result) => {
        console.log("onRegionPickerChange invoked");
        console.log(result);

        const filename = result.id + ".json";
        const url = dataBucketUrl + this.props.currentMap.path + "region/" + filename
        fetch(url)
            .then(response => response.json())
            .then(recordsForRegion => {
                this.setState({ barChartData: recordsForRegion });
            })
    }

    render() {
        return (
            <div>
                <div style={perRegionStatisticsChart}>

                    <Container style={{ margin: 0 }}>
                        <SearchBox
                            options={this.state.currentRegionsArray}
                            onRegionSelect={this.onRegionPickerSelect}
                        />
                    </Container>

                    <div style={{ position: "absolute", bottom: 5, left: "50%", color: "white" }}>Time</div>

                    <ResponsiveBar
                        data={this.state.barChartData}
                        keys={['value']}
                        indexBy="date"
                        margin={{ top: 20, right: 30, bottom: 50, left: 50 }}
                        padding={0.05}
                        colors={['#1fbad6','#137586']}
                        theme={{
                            axis: {
                                ticks: {
                                    text: {
                                        fill: "white"
                                    }
                                },
                                legend: {
                                    text: {
                                        fill: "white"
                                    }
                                }
                            },
                            grid: {
                                line: {
                                    stroke: "#42495c"
                                }
                            }
                        }}
                        borderColor={{ from: 'color', modifiers: [['darker', '1.6']] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Metric',
                            legendPosition: 'middle',
                            legendOffset: -45
                        }}
                        enableGridY={true}
                        enableGridX={false}
                        enableLabel={false}
                        labelTextColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
                        // legends={[
                        //     {
                        //         color: '#ffffff',
                        //         dataFrom: 'keys',
                        //         anchor: 'top',
                        //         direction: 'row',
                        //         justify: false,
                        //         translateX: -155,
                        //         translateY: -40,
                        //         itemsSpacing: 2,
                        //         itemWidth: 70,
                        //         itemHeight: 20,
                        //         itemDirection: 'left-to-right',
                        //         itemOpacity: 0.85,
                        //         symbolSize: 20,
                        //         effects: [
                        //             {
                        //                 on: 'hover',
                        //                 style: {
                        //                     itemOpacity: 1
                        //                 }
                        //             }
                        //         ]
                        //     }
                        // ]}
                        tooltip={({ data }) => (
                            <strong>
                                Value: {data.value}<br /> 
                                Date: {data.date}  <br />
                                Rec. Type: {data.recordType}
                            </strong>
                        )}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div>
            </div>

        );

    }

}

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);
